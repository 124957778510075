<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import Sort from "@/components/sort"
import TypePageContent from "@/components/TypePageContent"
import {
  formatRegionNewsList,
  formatImportNews,
  RegionSettingSchema,
  checkFromData,
  cleanUpData
} from '@/views/pages/marketing-slot/home-index/common.js'
import layout1 from '@/assets/images/new-use/types-layout-1.svg'
import layout2 from '@/assets/images/new-use/types-layout-2.svg'
import layout3 from '@/assets/images/new-use/types-layout-3.svg'
import layout4 from '@/assets/images/new-use/types-layout-4.svg'
import layout5 from '@/assets/images/new-use/types-layout-5.svg'

/**
 * Starter component
 */
export default {
  name: "TypePageEdit",
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      title: "分類頁設定",
      items: [
        {
          text: "分類頁管理",
          href: "/announcement",
        },
        {
          text: "分類頁編輯",
          active: true,
        }
      ],
      isSubTypeEdit: false,
      currentRegionName: "",
      // 編輯區塊標題
      editTitle: '',

      newsTypePageHashId: "",
      typeHashId: "",
      regionType: "",

      formInfo: [],
      showSort: false,
      maxCount: 10,

      // 版面配置選項
      layoutList: [
        {
          thumb: layout1,
          text: '新聞列表-版面1',
          caption: '顯示 3 則新聞',
          value: 1,
          pageTypes: [1, 2]
        },
        {
          thumb: layout2,
          text: '新聞列表-版面2',
          caption: '顯示 5 則新聞',
          value: 2,
          pageTypes: [1]
        },
        {
          thumb: layout3,
          text: '新聞列表-版面3',
          caption: '顯示 7 則新聞',
          value: 3,
          pageTypes: [1]
        },
        {
          thumb: layout4,
          text: '新聞列表-版面2',
          caption: '顯示 7 則新聞',
          value: 4,
          pageTypes: [2]
        },
        {
          thumb: layout5,
          text: '新聞列表-版面3',
          caption: '顯示 9 則新聞',
          value: 5,
          pageTypes: [2]
        }
      ],
      selectedLayout: '',
      // 新聞配置切換
      tabIndex: 0,

      // 只有內容配置的區塊
      contentRegion: ['20', '15', '16', '17', '18', '33', '29', '30', '31'],
      // 內容配置分頁切換
      contentTabIndex: 0,
      sendingRequest: false,
    };
  },
  computed: {
    isContentRegion () {
      return this.contentRegion.includes(this.regionType)
    },
    isContentTab () {
      const tabs = ['16', '17', '18', '29', '30', '31']
      return tabs.includes(this.regionType)
    },
    currentLayoutList () {
      return this.layoutList.filter(_layout => _layout.pageTypes.includes(this.isSubTypeEdit ? 2 : 1))
    }
  },
  mounted () {
    this.newsTypePageHashId = this.$route.params.NewsTypePageHashID
    this.typeHashId = this.$route.params.TypeHashID
    this.regionType = this.$route.params.RegionType
    this.currentRegionName = this.geCurrentRegionName(this.regionType)
    this.getRegionSetting(this.newsTypePageHashId, this.typeHashId, this.regionType)
    this.isSubTypeEdit = this.$route.query.subtype === 'true'
  },
  components: {
    Layout,
    PageHeader,
    DatePicker,
    Select2,
    Sort,
    TypePageContent
  },
  watch: {
    contentTabIndex (_val) {
      const region = this.isSubTypeEdit ? _val + 29 : _val + 16
      this.$router.push(`/types-page/edit/${this.newsTypePageHashId}/${this.typeHashId}/${region}`)
      this.editTitle = ""
      this.getRegionSetting(this.newsTypePageHashId, this.typeHashId, region.toString())
      this.currentRegionName = this.geCurrentRegionName(this.regionType)
    },
    selectedLayout (_val) {
      let articleMaxCountList = [0, 3, 5, 7, 7, 9]
      this.maxCount = articleMaxCountList[_val]
      console.log('change layout', _val)
    }
  },
  methods: {
    // 取得目前區域名稱
    geCurrentRegionName (_region) {
      switch (_region) {
        case "20":
          return "大輪播圖"
        case "15":
          return "分類頁第一頻"
        case "16":
        case "17":
        case "18":
        case "29":
        case "30":
        case "31":
          return "側邊分頁"
        case "33":
          return "影音新聞列表"
        default:
          return "新聞列表"
      }
    },
    // 取得目前區域設定
    getRegionSetting (_newsTypePageHashId, _typeHashId, _regionType) {
      this.regionType = _regionType
      if (this.contentRegion.includes(_regionType.toString())) {
        // 一般區塊設定
        this.$root.apis.getNewsTypePageRegionSetting(_typeHashId, _regionType, this.getRegionSettingSuccess, this.getRegionSettingError)
      } else {
        // 新聞列表設定
        this.$root.apis.getNewsTypeLayoutAndContentSetting(_newsTypePageHashId, _regionType, this.getNewsTypeLayoutAndContentSettingSuccess, this.getRegionSettingError)
      }
    },
    getRegionSettingSuccess (_response) {
      this.editTitle = _response.body.data.title
      this.formInfo = formatRegionNewsList(_response.body.data.news)
      this.selectedLayout = _response.body.data.template
    },
    getNewsTypeLayoutAndContentSettingSuccess (_response) {
      this.formInfo = formatRegionNewsList(_response.body.data.content)
      this.editTitle = _response.body.data.setting.name
      this.selectedLayout = _response.body.data.setting.template
    },
    getRegionSettingError (_error) {
      console.log(_error)
    },
    // 新聞快速帶入
    onFastImportNewsList (_news) {
      console.log('=> 接收自動帶入的新聞清單:', _news)
      this.formInfo = _news.map(_article => {
        return formatImportNews(_article)
      })
    },
    // 操作 News-Item
    addNewOne () {
      this.formInfo.push({ ...RegionSettingSchema })
    },
    deleteOne (_index) {
      this.formInfo.splice(_index, 1)
    },
    setItemData (_setItem, _index) {
      this.formInfo.splice(_index, 1, _setItem)
    },
    // 排序
    onSortClick () {
      this.showSort = true
    },
    onSort (_sorted) {
      console.log(_sorted)
      this.formInfo = _sorted
      this.showSort = false
    },
    // 暫存設定
    saveCurrentSetting () {
      if (this.isContentRegion) {
        // 暫存目前區域設定
        this.saveRegionSetting()
      } else {
        // 根據配置暫存新聞列表設定
        this.updateNewsTypeSetting()
      }
    },
    saveRegionSetting () {
      console.log('=> 開始儲存目標[' + this.typeHashId + ']版位[' + this.regionType + ']的稿件設定');

      const vm = this
      if (!checkFromData(vm, this.formInfo)) {
        return
      }
      let setData = {
        name: this.editTitle || this.currentRegionName,
        items: cleanUpData(this.formInfo)
      }
      this.sendingRequest = true
      this.$root.apis.saveNewsTypePageRegionSetting(this.typeHashId, this.regionType, setData, this.saveRegionSettingSuccess, this.saveRegionSettingError)
    },
    checkNewsTypeRequiredData () {
      if (!this.editTitle) {
        this.$root.common.showErrorDialog('新聞列表名稱為必填！')
        return false
      }
      if (!this.selectedLayout) {
        this.$root.common.showErrorDialog('請確認選取版面配置！')
        return false
      }
      // if (this.formInfo.length === 0) {
      //   this.$root.common.showErrorDialog('內容配置為必填！')
      //   return false
      // }
      const vm = this
      if (!checkFromData(vm, this.formInfo)) {
        return false
      }
      return true
    },
    async updateNewsTypeSetting () {
      if (!this.checkNewsTypeRequiredData()) {
        return
      } else {
        // 版面內容
        const layoutData = {
          name: this.editTitle,
          template: this.selectedLayout
        }
        const layoutPromise = new Promise((resolve, reject) => {
          this.$root.apis.updateNewsTypeLayoutSetting(this.newsTypePageHashId, this.regionType, layoutData, resolve, reject)
        })

        // 新聞內容
        const contentData = {
          items: cleanUpData(this.formInfo)
        }
        const contentPromise = new Promise((resolve, reject) => {
          this.$root.apis.updateNewsTypeContentSetting(this.newsTypePageHashId, this.regionType, contentData, resolve, reject)
        })

        try {
          await Promise.all([layoutPromise, contentPromise])
          this.$root.common.showSingleNotify("儲存成功！")
        } catch (error) {
          this.$root.common.showErrorNotify(JSON.stringify(error.body.data))
        }
      }
    },
    saveRegionSettingSuccess (_response) {
      this.$root.common.showSingleNotify("儲存成功！")
      this.sendingRequest = false
    },
    saveRegionSettingError (_error) {
      this.$root.common.showErrorNotify(_error.body.data)
      this.sendingRequest = false
    },
    // 移除新聞列表設定
    removeNewsTypeLayout (_id = null) {
      const vm = this
      this.$root.common.confirmAction("刪除新聞列表", '確定要刪除此新聞列表設定嗎？', "刪除", "再想一下",
        function (_result) {
          vm.$root.apis.removeNewsTypeLayoutAndContentSetting(
            vm.newsTypePageHashId,
            vm.regionType,
            function (_response) {
              vm.$root.common.showSingleNotify("刪除成功！")
              vm.$router.push(`/types-page/setting/${vm.newsTypePageHashId}`)
            },
            function (_error) {
              vm.$root.common.showErrorNotify(_error.body.data)
            }
          )
        }
      )
    },
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  Sort(:show-sort="showSort" :data-list="formInfo" @sort="onSort")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" :to="'/types-page/setting/' + newsTypePageHashId") 回上一層
    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row.p-2.p-lg-0
            .header-title 編輯-{{ currentRegionName }}
            .header-buttons
              b-button.action-btn.width-lg.mr-1(variant="outline-secondary" :to="'/types-page/setting/' + newsTypePageHashId") 取消
              b-button.action-btn.width-lg.mr-1(variant="primary" @click="saveCurrentSetting" :disabled="sendingRequest") {{ sendingRequest ? '執行中...' : '確定' }}
              b-button.action-btn.width-lg.mr-1(v-if="!isContentRegion" variant="danger" @click="removeNewsTypeLayout(newsTypePageHashId)") 刪除

        //- 內容配置有分頁
        .mx-3.mt-3(v-if="isContentTab")
          .header-title 內容配置
          b-tabs.tab-color.mt-3(v-model="contentTabIndex")
            b-tab(title="分頁一", :regionType="regionType", active)
              .card-body
                TypePageContent(
                  :current-region-name="currentRegionName + '一'"
                  :current-region="regionType"
                  :current-target="typeHashId"
                  :form-info="formInfo"
                  :max-count="maxCount"
                  v-model="editTitle"
                  @set-item-data="setItemData"
                  @remove-item="deleteOne"
                  @add-news-item="addNewOne"
                  @sort-click="onSortClick"
                  @import-news="onFastImportNewsList"
                )
            b-tab(title="分頁二",:regionType="regionType")
              .card-body
                TypePageContent(
                  :current-region-name="currentRegionName + '二'"
                  :current-region="regionType"
                  :current-target="typeHashId"
                  :form-info="formInfo"
                  :max-count="maxCount"
                  v-model="editTitle"
                  @set-item-data="setItemData"
                  @remove-item="deleteOne"
                  @add-news-item="addNewOne"
                  @sort-click="onSortClick"
                  @import-news="onFastImportNewsList"
                )
            b-tab(title="分頁三",:regionType="regionType")
              .card-body
                TypePageContent(
                  :current-region-name="currentRegionName + '三'"
                  :current-region="regionType"
                  :current-target="typeHashId"
                  :form-info="formInfo"
                  :max-count="maxCount"
                  v-model="editTitle"
                  @set-item-data="setItemData"
                  @remove-item="deleteOne"
                  @add-news-item="addNewOne"
                  @sort-click="onSortClick"
                  @import-news="onFastImportNewsList"
                )


        //- 內容配置無分頁
        .mx-3.mt-3(v-else-if="isContentRegion")
          .header-title 內容配置
          b-card-body
            TypePageContent(
              :current-region-name="currentRegionName"
              :current-region="regionType"
              :current-target="typeHashId"
              :form-info="formInfo"
              :max-count="maxCount"
              v-model="editTitle"
              @set-item-data="setItemData"
              @remove-item="deleteOne"
              @add-news-item="addNewOne"
              @sort-click="onSortClick"
              @import-news="onFastImportNewsList"
            )

        //- 版面配置＆內容配置（新聞列表）
        b-tabs(v-else, v-model="tabIndex", pills, card, vertical, nav-class="bg-white p-0", active-tab-class="pt-1", nav-wrapper-class="col-2", content-class="pt-0")
          //- 版面配置
          b-tab(title="版面配置", active, title-item-class="mb-2")
            b-card-text
              b-card-header(header-tag="header", role="tab")
                .header-title.ml-2 版面配置
              br
              .p-2
                b-form-group
                  .row
                    .col-lg-3
                      .form-group.mb-3
                        label {{currentRegionName}}名稱
                        br
                        input.form-control(
                          v-model="editTitle"
                          type="text",
                          placeholder="請輸入區塊標題"
                        )
                  .row
                    .col-12.col-lg-3.mr-2(v-for="layout in currentLayoutList")
                      b-img.layout-img(
                        fluid 
                        :src="layout.thumb" 
                        :alt="'layout-' + layout.value"
                        @click.stop="selectedLayout = layout.value")
                      b-form-radio.mt-2(
                        v-model="selectedLayout"
                        :key="layout.value"
                        :value='layout.value') {{isSubTypeEdit ? '子分類' : ''}}{{layout.text}}
                      p.mt-2.ml-2 {{ layout.caption }}

          //- 內容配置
          b-tab(title="內容配置", title-item-class="mb-2")
            b-card-text
              b-card-header(header-tag="header", role="tab")
                .header-row
                  .header-title 內容配置
              br
              .p-2
                TypePageContent(
                  :is-news-content="true"
                  :is-sub-type="isSubTypeEdit"
                  :region-type="(isSubTypeEdit) ? 32 : 19"
                  :current-region="regionType"
                  :current-target="typeHashId"
                  :form-info="formInfo"
                  :max-count="maxCount"
                  v-model="editTitle"
                  @set-item-data="setItemData"
                  @remove-item="deleteOne"
                  @add-news-item="addNewOne"
                  @sort-click="onSortClick"
                  @import-news="onFastImportNewsList"
                )



</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1
  .header-buttons
    width: auto

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .nav-pills 
  border-right: 1px solid #ccc
  .nav-link
    padding: 1rem
    text-align: center
    border-radius: 0
::v-deep .nav-tabs
  justify-content: space-between
  .nav-item
    flex: 1
    text-align: center

::v-deep .tab-pane
  padding-left: 0
  padding-right: 0

::v-deep.tabs.row.no-gutters
  flex-wrap: nowrap

@media  (max-width: 992px)
  .header-row
    flex-direction: column
    .header-title
      align-self: flex-start
    .header-buttons
      width: 100%
  .card-header
    padding: 1rem 0.5rem
  ::v-deep .card-text
    min-width: 240px
  ::v-deep .tab-content
    overflow: scroll
  ::v-deep .nav-pills 
    .nav-link
      padding: 1rem 0.5rem
  .action-btn
    display: block
    width: 100%
    margin: 0.5rem 0
</style>
