<template lang="pug">
.type-page-content
  .form.form-horizontal(role="form")
    FastImport(
      :target-id="(regionType) ? currentRegion : currentTarget"
      :region-type="parseInt( (regionType) ? regionType : currentRegion)"
      :show-title="true" 
      :show-max-count="true"
      :max-count="maxCount"
      @import="$emit('import-news', $event)"
    )
    .row(v-if="showRegionNameInput")
      .col-lg-3
        .form-group.mb-3
          label {{currentRegionName}}名稱
          br
          input.form-control(
            :value="value"
            @input="$emit('input', $event.target.value)"
            type="text",
            placeholder="請輸入區塊標題"
          )
    .row.ml-1.ml-lg-0(v-if="!isSubType")
      b-button.col-lg-2.width-md.mb-2(variant="primary" v-b-modal.modal-sort @click="$emit('sort-clik')") 編輯排序
    .row  
      .col 
        .card.col-lg-9(v-for="(item,index) of formInfo")
          NewsItem(
            :key="`region-${currentRegion}-NewsItemList-${index}${item.newsId}`"
            :canUseAd="isNewsContent"
            :regionId="currentRegion"
            :item="item" 
            :index="index"
            :allow-edit="!isSubType" 
            @setItemData="setItemData"
            @removeItem="$emit('remove-item', $event)"
        )
    .row.ml-1.ml-lg-0(v-if="!isSubType")
      b-button.col-lg-9.add-btn.width-lg.mb-2(
        v-if="formInfo.length < maxCount" 
        variant="outline-primary" 
        @click="$emit('add-news-item')"
      ) ＋新增一則 （最多{{maxCount}}則） 
</template>

<script>
import NewsItem from "@/components/news-item/news-item"
import FastImport from "@/components/FastImport"

export default {
  name: 'TypePageContent',
  props: {
    formInfo: {
      type: Array,
      default: () => []
    },
    maxCount: {
      type: Number,
      default: 10
    },
    regionType: {
      type: Number,
      default: 0
    },
    currentRegion: {
      type: String,
      default: null
    },
    currentTarget: {
      type: String,
      default: null
    },
    currentRegionName: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    isNewsContent: {
      type: Boolean,
      default: false
    },
    isSubType: {
      type: Boolean,
      default: false
    }
  },
  components: {
    NewsItem,
    FastImport,
  },
  data () {
    return {
      selected: 'news',
      // 可編輯區塊名稱
      canEditRegionName: ['15', '16', '17', '18', '29', '30', '31', '33'],
    }
  },
  computed: {
    showRegionNameInput () {
      return this.canEditRegionName.includes(this.currentRegion)
    }
  },
  methods: {
    setItemData (_setItem, _index) {
      this.$emit('set-item-data', _setItem, _index)
    }
  }
}
</script>