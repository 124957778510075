<template lang="pug">
b-modal#modal-sort(
  v-model="modalShow"
  title="排序", 
  title-class="font-18", 
  body-class="p-4" 
  size="md"
)
  label.mb-3 可上下拖曳標題，進行排序
  .sort-list
    draggable(v-model="sortedList", group="people", :options="{ draggable : '.draggable' }")
      .sort-item.pr-2.pl-2.pt-3.pb-3(v-for="item of sortedList", :class="adClass(item)")
        i.fe-menu
        .ipt-box {{ item.useAd ? '廣告' : item.title }}
  template(#modal-footer='{ ok, cancel, hide }')
    b-button(size='md' variant='danger' @click='cancel()')
      | 取消操作
    b-button(size='md' variant='success' @click='clickOk()')
      | 確認

</template>
<script>
import draggable from 'vuedraggable'
export default {
  props: ['showSort', 'dataList', 'canAdSort'],
  data() {
    return {
      sortedList: [],
      modalShow: false
    }
  },
  components: {
    draggable
  },
  watch: {
    dataList(_list) {
      this.sortedList = JSON.parse(JSON.stringify(_list))
    },
    showSort(_status) {
      this.modalShow = _status
    }
  },
  methods: {
    clickOk() {
      this.$emit('sort', this.sortedList)
      this.modalShow = false
    },
    adClass(_item) {
      return _item.useAd
        ? this.canAdSort ? 'ad-item draggable' : 'ad-item forbid'
        : 'draggable'
    }
  }
}
</script>
<style lang="sass" scoped>
.sort-item
  display: flex
  align-items: center
  gap: 20px
  background: #f1f5f9
  margin-bottom: 5px
  cursor: pointer

.ad-item
  background: #FF846E4D

.forbid
  cursor: not-allowed

.draggable
  cursor: grab
</style>
